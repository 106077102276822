"use client";
import { useConfiguration } from "@/hooks/useConfiguration";
import { IncidentFilter } from "@/types";
import { storage } from "@/util/storage";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "next/navigation";
import { useSelf } from "./useSelf";
import { api } from "@/api";
import { useCallTypes } from "@/context/calltype-context";

type Props = Omit<
  UseQueryOptions<IncidentFilter, Error, IncidentFilter, string[]>,
  "queryKey" | "queryFn"
> | void;

export function useIncidentFilter(props: Props) {
  const searchParams = useSearchParams();
  const { data: configuration } = useConfiguration();
  const { data: self } = useSelf();
  const { businessSubscriptions, userSubscriptions } = useCallTypes();

  // console.log("business subscriptions:     ", businessSubscriptions);
  // console.log("user subscriptions:     ", userSubscriptions);

  return useQuery<IncidentFilter, Error, IncidentFilter, string[]>({
    enabled: !!configuration && !!self?.id,
    staleTime: 30000,
    queryKey: ["filters", "incidents"],
    queryFn: async () => {
      const today = dayjs().startOf("day");
      const tomorrow = today.add(1, "day");
      const expiresAt = tomorrow.toISOString();

      const defaultDates = {
        from: dayjs().subtract(12, "hours").toISOString(),
        to: dayjs().endOf("day").toISOString(),
      };

      const configPresets = configuration?.incidentPresets
        ? [...configuration.incidentPresets]
        : [];

      let incidentFilter: IncidentFilter = {
        keywords: searchParams.get("keywords") || "",
        location: searchParams.get("location") || "",
        incidentStatus: "active",
        limit: 100,
        ...defaultDates,
        radius: 30,
        presets: configPresets,
        active: true,
        propertyAddress: true,
        legalDetails: false,
        stateIds: [],
        countyInclusions: [],
        countyExclusions: [],
        cityInclusions: [],
        cityExclusions: [],
        allCountyStateIds: [],
        category: "verified",
      };

      try {
        const storedIncidentFilter = await storage.get("incidentFilter");
        if (storedIncidentFilter) {
          const stored = JSON.parse(storedIncidentFilter);

          if (stored.expiresAt && dayjs().isAfter(dayjs(stored.expiresAt))) {
            console.log("Incident filter expired, using default values");
          } else {
            const currentPresets = incidentFilter.presets;

            incidentFilter = {
              ...incidentFilter,
              from: stored.from || incidentFilter.from,
              to: stored.to || incidentFilter.to,
              stateIds: stored.stateIds || incidentFilter.stateIds,
              countyInclusions:
                stored.countyInclusions || incidentFilter.countyInclusions,
              countyExclusions:
                stored.countyExclusions || incidentFilter.countyExclusions,
              cityInclusions:
                stored.cityInclusions || incidentFilter.cityInclusions,
              cityExclusions:
                stored.cityExclusions || incidentFilter.cityExclusions,
              allCountyStateIds:
                stored.allCountyStateIds || incidentFilter.allCountyStateIds,
              presets:
                stored.presets && stored.presets.length > 0
                  ? stored.presets
                  : currentPresets,
            };
          }
        }
      } catch (error) {
        console.warn("Error loading stored incident filter:", error);
      }

      if (self?.id) {
        try {
          const userPresetsResponse = await api.get(
            `users/${self.id}/subscriptions/presets`,
          );

          const userPresets =
            userPresetsResponse.data?.presets?.map((p: any) => p.label) || [];
          if (userPresets.length > 0) {
            incidentFilter.presets = userPresets;
          } else {
          }
        } catch (error) {
          console.error("Error fetching user presets:", error);
        }
      }

      try {
        await storage.set(
          "incidentFilter",
          JSON.stringify({
            ...incidentFilter,
            expiresAt: expiresAt,
          }),
        );
      } catch (error) {
        console.warn("Error saving incident filter:", error);
      }
      return incidentFilter;
    },
    ...props,
  });
}
